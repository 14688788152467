export const convertToDate = (date: string): string =>
  new Date(date).toLocaleDateString("dk").replace(/\//g, "-");
export const convertToCurency = (number: number, separator = ".", decimalSeparator = ','): string => {
  //(number ?? 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  const parts = number.toString().split(".");
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
  const decimalPart = parts[1] ? decimalSeparator + parts[1] : "";
  return integerPart + decimalPart;
}

export const convertToQueryString = (params: any) =>
  Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
