import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { CO2EmissionSourceGroup, partsToShow } from "../../CO2Plan";
import { Grid, ListItemButton, Paper, TextField, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { t } from "i18next";
import { useCO2 } from "../../co2plan.actions";
import { CO2Context } from "../CO2ContextWrapper";
import { LoadingButton } from "@mui/lab";
import { getIsQuestionsFilled } from "./CO2InitialSurveyComponent";

type Props = {
  group: CO2EmissionSourceGroup;
  widthSaveButton?: boolean;
  showNextSideMenu?: () => void;
};

const CustomQuestionsComponent = ({ group, widthSaveButton, showNextSideMenu }: Props) => {
  const [state, actions] = useCO2();
  const context = useContext(CO2Context);
  const [isQuestionsFilled, setIsQuestionsFilled] = useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const saveHandler = useMemo(() => {
    const saveEmission = async () => {
      setLoading(true);
      try {
        await context.saveEmissionSurvey(
          state.co2Plan?.InitialSurvey || null,
          state.co2Plan?.TargetSurvey || null,
          state.co2Plan?.CustomQuestions || null,
          state.co2Plan?.Id,
          context.unit?.Id,
          context.isMultiSelect
        );

        if (showNextSideMenu) {
          showNextSideMenu();
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    return saveEmission;
  }, [group]);

  const textFieldChangeHandler = useCallback(
    (value: string, sourceId: string, questionId: string, personId?: string) => {
      if (state.co2Plan) {
        actions.selectCustomAnswer(sourceId, questionId, state.co2Plan?.CustomQuestions, personId, value);
      }
    },
    [state.co2Plan?.CustomQuestions]
  );

  useEffect(() => {
    setIsQuestionsFilled(getIsQuestionsFilled(group));
  }, [group]);

  return (
    <Grid container direction="column" alignItems="center" marginTop={0} spacing={2}>
      <Grid container item direction="column" alignItems="center">
        {group.PersonEmissionSources.map((personSource) => (
          <>
            <Paper sx={{ padding: isXs ? "40px 8px" : "40px 80px", width: "100%" }}>
              <Grid
                item
                my={3}
                container
                alignItems="center"
                justifyContent="center"
                spacing={1}
                key={personSource.PersonId}
              >
                <Grid item>
                  <Typography variant="h5">{personSource.Name}, </Typography>
                </Grid>
                <Grid item>
                  {" "}
                  <Typography variant="h6" color="secondary">
                    {t("ClimatePlan.InitialSurvey.ChooseText")}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item direction="column" alignItems="center" spacing={1}>
                {personSource.EmissionSources.map((emissionSource) => (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={1}
                    key={emissionSource.Id}
                    wrap="nowrap"
                    width="100%"
                  >
                    <Grid item>
                      <Typography
                        my={3}
                        align="center"
                        variant="h4"
                        sx={{
                          margin: "0 30px 20px",
                        }}
                      >
                        {t(emissionSource.Name)}
                      </Typography>
                    </Grid>
                    <Grid container item direction="row" spacing={2} justifyContent="center">
                      {emissionSource.ChildSources.map((source) => {
                        return source.Question ? (
                          <Grid item xs={12} sm={6} key={source.Id}>
                            <TextField
                              label={t(source.Name) || ""}
                              variant="outlined"
                              style={{ width: "100%", minHeight: "80px" }}
                              defaultValue={source.MetaValue}
                              onChange={(e) =>
                                textFieldChangeHandler(
                                  e.target.value,
                                  source.Id,
                                  emissionSource.Id,
                                  personSource.PersonId
                                )
                              }
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} key={source.Id}>
                            <StyledListItem
                              selected={source.IsSelected || !!source.IsInitiallySelected}
                              onClick={() =>
                                state.co2Plan &&
                                actions.selectCustomAnswer(
                                  source.Id,
                                  emissionSource.Id,
                                  state.co2Plan.CustomQuestions,
                                  personSource.PersonId
                                )
                              }
                            >
                              <Typography color="inherit" variant="body1">
                                {t(source.Name)}
                              </Typography>
                            </StyledListItem>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </>
        ))}
        <Paper sx={{ padding: isXs ? "40px 8px" : "40px 80px", width: "100%" }}>
          <Grid container item direction="column" alignItems="center" spacing={1}>
            {group.HouseHoldEmissionSources.map((houseHoldSource) => (
              <Grid
                container
                direction="column"
                alignItems="center"
                spacing={1}
                key={houseHoldSource.Id}
                wrap="nowrap"
                width="100%"
              >
                <Grid item>
                  <Typography
                    my={1}
                    align="center"
                    variant="h4"
                    sx={{
                      margin: "0 30px 20px",
                    }}
                  >
                    {t(houseHoldSource.Name)}
                  </Typography>
                </Grid>
                <Grid item container>
                  <Grid container item direction="row" spacing={2} justifyContent="flex-start">
                    {houseHoldSource.ChildSources.map((source) => {
                      return source.Question ? (
                        <Grid item xs={12} sm={6} key={source.Id}>
                          <TextField
                            label={t(source.Name) || ""}
                            variant="outlined"
                            style={{ width: "100%", minHeight: "80px" }}
                            defaultValue={source.MetaValue}
                            onChange={(e) => textFieldChangeHandler(e.target.value, source.Id, houseHoldSource.Id)}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} key={source.Id}>
                          <StyledListItem
                            selected={source.IsSelected || !!source.IsInitiallySelected}
                            onClick={() =>
                              state.co2Plan &&
                              actions.selectCustomAnswer(source.Id, houseHoldSource.Id, state.co2Plan.CustomQuestions)
                            }
                          >
                            <Typography color="inherit" variant="body1">
                              {t(source.Name)}
                            </Typography>
                          </StyledListItem>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Grid>
      {widthSaveButton && (
        <LoadingButton
          disabled={!isQuestionsFilled}
          loading={loading}
          variant="contained"
          style={{ marginTop: "20px" }}
          onClick={saveHandler}
        >
          {t("ClimatePlan.Buttons.Save")}
        </LoadingButton>
      )}
    </Grid>
  );
};

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  cursor: "pointer",
  background: theme.palette.background.lightPaper,
  minHeight: 80,
  height: "100%",
  display: "flex",
  maxHeight: "50px",
  alignItems: "center",
  border: "1px solid" + theme.palette.background.grayPaper,
  "&.Mui-selected": {
    backgroundColor: theme.palette.background.grayPaper,
    color: theme.palette.primary.contrastText,
    "&.Mui-focusVisible": {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
  },
  "&:hover": {
    [theme.breakpoints.up("md")]: {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.grayPaper,
      color: theme.palette.primary.contrastText,
    },
  },
}));

export default CustomQuestionsComponent;
