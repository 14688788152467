import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import { ThemeProvider, StyledEngineProvider, Grid, Skeleton, Paper, useTheme, useMediaQuery } from "@mui/material";
import store from "../../../../reducers/store";
import { getOptionalTheme } from "../../../../constants/theme";
import { isReactComponent } from ".";
import { GrapesEditor, GrapesTypes, LoadComponentOptions } from "../../defenitions";
import CO2PlanAdminService from "../../../../services/admin/admin.co2plan.service";
import { useLandingActions } from "../../../../actions";
import {
  CO2EmissionSourceGroup,
  CO2Plan,
  CO2PlanCustomQuestionsTarget,
} from "../../../../pages/ClimatePlan/shared/CO2Plan";
import i18n from "../../../../i18n";
import { CO2PlanActionTypes } from "../../../../pages/ClimatePlan/shared/co2plan.actiontypes";
import { NIL } from "uuid";
import { integrateCustomQuestionsIntoGroups, useCO2 } from "../../../../pages/ClimatePlan/shared/co2plan.actions";
import CustomSurveyComponent from "../../../../pages/ClimatePlan/shared/components/InitialSurvey/CustomExternalSurveyComponent";

type CO2PlanMetaQuestionsProps = {
  metaQuestionId: string;
};

export const CO2PlanMetaQuestions = (props: CO2PlanMetaQuestionsProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [state, co2Actions] = useCO2();
  const [questionsGroup, setQuestionsGroup] = useState<CO2EmissionSourceGroup>();
  const landingActions = useLandingActions();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    if (!questionsGroup) {
      setLoading(true);
      landingActions
        .getCO2Meta(props.metaQuestionId)
        .then((res) => {
          if (res) {
            res.Translations.forEach((item) => {
              if (!item?.LanguageId) return;
              i18n.addResource(item.LanguageId, "translation", item.KeyPath, item.Text, {
                silent: false,
                keySeparator: ".",
              });
            });

            // const group = res.CO2CategoryGroup.EmissionSourceGroups[0];
            // for (let i = 1; i < res.CO2CategoryGroup.EmissionSourceGroups.length; i++) {
            //   const item = res.CO2CategoryGroup.EmissionSourceGroups[i];
            //   group.HouseHoldEmissionSources = group.HouseHoldEmissionSources.concat(item.HouseHoldEmissionSources);
            //   group.PersonEmissionSources = group.PersonEmissionSources.concat(item.PersonEmissionSources);
            // }

            // console.log("group", group);
            // setQuestionsGroup(group);

            dispatch({
              type: CO2PlanActionTypes.SET_CO2_PLAN,
              payload: {
                Id: NIL,
                PlanPersons: [],
                PlanPersonsToIgnore: [],
                IsSinglePersonPlan: false,
                InitialSurvey: {
                  Categories: [],
                  IsFilledIn: false,
                  Error: null,
                  MissingSourceGroups: [],
                  MissingSources: [],
                  Questions: [],
                  ActiveGroupIndex: 0,
                  HasInvalidInput: false,
                  Year: new Date().getFullYear(),
                },
                TargetSurvey: {
                  Categories: [],
                  IsFilledIn: false,
                  Error: null,
                  MissingSourceGroups: [],
                  MissingSources: [],
                  Questions: [],
                  ActiveGroupIndex: 0,
                  HasInvalidInput: false,
                  Year: new Date().getFullYear(),
                },
                CustomQuestions: [
                  {
                    IsFilledIn: false,
                    PlaceAfterSourceId: null,
                    MainToolbaPlacement: null,
                    Target: CO2PlanCustomQuestionsTarget.MainToolBar,
                    Questions: res.CO2CategoryGroup.EmissionSourceGroups.map((group) => ({
                      Id: group.Id,
                      Name: group.Name,
                      Code: group.Code,
                      CO2Emission: group.CO2Emission,
                      EmissionSourceGroups: [group],
                    })),
                  },
                ],
                CO2CategoryGroup: { EmissionSourceGroups: [] },
                Translations: [],
                DefaultCO2Emission: 0,
                GovernmentGoalCO2Emission: 0,
                InitialHouseholdCO2Emission: 0,
                TargetHouseholdCO2Emission: 0,
                InitialPersonCO2Emission: 0,
                TargetPersonCO2Emission: 0,
                InitialHouseholdCO2EmissionPerPerson: 0,
                TargetHouseholdCO2EmissionPerPerson: 0,
                InitialSurveyHistory: [],
                TargetSurveyHistory: [],
                CO2EmissionSourceGroups: [],
                CO2EmissionSources: [],
                CO2EmissionSourceQuestions: [],
                CO2EmissionSourceQuestionAnswers: [],
                IsInitialSurveyOutdated: false,
                ActionsTimeLine: [],
                IsMultiSelect: false,
                UnchangeableBasicEmission: 0,
              } as CO2Plan,
            });
          }
        })
        .finally(() => setLoading(false));
    }

    return () => {
      dispatch({
        type: CO2PlanActionTypes.SET_CO2_PLAN,
        payload: null,
      });
    };
  }, []);

  useEffect(() => {
    if (state.co2Plan?.CustomQuestions) {
      const group = integrateCustomQuestionsIntoGroups(
        state.co2Plan.CustomQuestions,
        [],
        CO2PlanCustomQuestionsTarget.MainToolBar
      );

      setQuestionsGroup(group[0]);
    }
  }, [state.co2Plan?.CustomQuestions]);

  return (
    <Grid container spacing={1} alignItems="stretch" justifyContent="center">
      {loading && (
        <Paper sx={{ padding: isXs ? "20px 8px" : "20px 80px", marginTop: 2, width: "100%" }}>
          <Grid item container direction="column" my={1} spacing={2}>
            <Grid item>
              <Skeleton variant="rectangular" width={300} animation="wave" height={20} />
            </Grid>
            <Grid item container>
              <Skeleton sx={{ width: "100%" }} variant="rectangular" animation="wave" height={80} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={300} animation="wave" height={20} />{" "}
            </Grid>
            <Grid item container>
              <Skeleton sx={{ width: "100%" }} variant="rectangular" animation="wave" height={80} />
            </Grid>
            <Grid item>
              <Skeleton variant="rectangular" width={300} animation="wave" height={20} />
            </Grid>
            <Grid item container>
              <Skeleton sx={{ width: "100%" }} variant="rectangular" animation="wave" height={80} />
            </Grid>
          </Grid>
        </Paper>
      )}
      {!loading && questionsGroup && (
        <CustomSurveyComponent
          group={questionsGroup}
          widthSaveButton
          saveQuestionsHandler={() => {
            if (state.co2Plan)
              landingActions.saveCO2MetaAnswers(
                state.co2Plan.Id,
                searchParams.get("email") || null,
                co2Actions.getGroupsFromQuestions(state.co2Plan.CustomQuestions)
              );
          }}
        />
      )}
    </Grid>
  );
};

export default function (editor: GrapesEditor, options: LoadComponentOptions, landingTheme?: string): void {
  const domComponents = editor.DomComponents;
  const blockManager = editor.BlockManager;
  const defaultType = domComponents.getType("default");
  const { model, view } = defaultType;
  const compName = "CO2PlanMetaQuestions";

  blockManager.add(GrapesTypes.CO2PlanMetaQuestionsComponent, {
    label: "CO2 Meta question",
    category: options.categoryLabel,
    attributes: { class: "fa fa-fax" },
    content: `<div data-gjs-type="${GrapesTypes.CO2PlanMetaQuestionsComponent}"></div>`,
  });
  const traits = [
    {
      type: "select",
      label: "CO2 Meta question",
      name: "metaQuestionId",
      changeProp: 1,
      typeid: 0,
      options: [],
    },
  ];
  const subscriptions = traits.map((trait) => trait.name);

  const getCO2PlanMetaItems = async () => {
    const co2MetaItems = (await CO2PlanAdminService.getCO2PlanMetaItems(0, 200)).Results.map((g) => ({
      id: g.Id,
      name: g.DisplayName,
    }));

    return co2MetaItems;
  };

  domComponents.addType(GrapesTypes.CO2PlanMetaQuestionsComponent, {
    isComponent(el: HTMLElement) {
      if (
        (el.getAttribute && el.getAttribute("data-gjs-type") === GrapesTypes.CO2PlanMetaQuestionsComponent) ||
        (el.attributes && (el.attributes as any)["data-gjs-type"] === GrapesTypes.CO2PlanMetaQuestionsComponent) ||
        isReactComponent(el, compName)
      ) {
        return {
          type: GrapesTypes.CO2PlanMetaQuestionsComponent,
        };
      }
    },
    model: {
      defaults: {
        ...model.prototype.defaults,
        droppable: false,
        variant: "outlined",
        color: "primary",
        traits: [...model.prototype.defaults.traits, ...traits],
      },
    },
    view: view.extend({
      init(element: any) {
        getCO2PlanMetaItems().then((res) => {
          element.model.getTrait("metaQuestionId").set("options", res);
          const metaQuestionId = this.model.getTrait("metaQuestionId").getValue();
          const selectedMetaQuestion = res.find((e) => e.id == metaQuestionId);
          if (selectedMetaQuestion) {
            element.model.getTrait("metaQuestionId").set("value", selectedMetaQuestion.id);
          }
        });
        subscriptions.forEach((subscription) => {
          this.listenTo(this.model, `change:${subscription}`, this.handleChanges);
        });
      },
      handleChanges() {
        const root = ReactDOM.createRoot(this.el);
        root.unmount();
        this.render();
      },
      onRender() {
        const { el } = this;
        const comps = this.model.get("components");
        const { metaQuestionId } = this.model.attributes;
        comps.reset();
        const compString = `<${compName} metaQuestionId=${metaQuestionId}></${compName}>`;
        comps.add(compString);
        const root = ReactDOM.createRoot(el);

        root.render(
          <>
            <StyledEngineProvider>
              <ThemeProvider theme={getOptionalTheme(landingTheme)}>
                <Provider store={store}>
                  <CO2PlanMetaQuestions metaQuestionId={metaQuestionId} />
                </Provider>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        );
      },
    }),
  });
}
