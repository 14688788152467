import React, { useState, useContext, useEffect, useMemo } from "react";
import { Grid, Button, useMediaQuery, useTheme, styled, Typography, Paper, Alert, Snackbar } from "@mui/material";
import { CO2EmissionSurvey, CO2EmissionSourceGroup, CO2PlanPerson, CO2PlanCustomQuestionsTarget } from "../../CO2Plan";
import CO2EmissionSourceGroupComponent from "./CO2EmissionSourceGroupComponent";
import CO2StepperComponent from "../CO2StepperComponent";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ButtonsComponent from "../ButtonsComponent";
import { CO2Context } from "../CO2ContextWrapper";
import CustomQuestionsComponent from "./CustomQuestionsComponent";
import { integrateCustomQuestionsIntoGroups, useCO2 } from "../../co2plan.actions";
import { useTranslation } from "react-i18next";
import CO2InitialSurveyUpdateComponent from "./CO2InitialSurveyUpdateComponent";
import { IsGroupFilled } from "../../co2-helpers";

type CO2InitialSurveyProps = {
  survey: CO2EmissionSurvey;
  scrollToTop: () => void;
  finishSurvey: () => Promise<void>;
  openFamilyDialog: () => void;
  planPersons: CO2PlanPerson[];
  isReadonly?: boolean;
  ignorePlanPersons?: boolean;
};

export const getIsQuestionsFilled = (group: CO2EmissionSourceGroup) => {
  for (const personSource of group.PersonEmissionSources) {
    let isSourceFilled = false;
    personSource.EmissionSources.forEach((source) => {
      for (const childSource of source.ChildSources) {
        if (childSource.Question && childSource.MetaValue) isSourceFilled = true;
        if (!childSource.Question && childSource.IsSelected) isSourceFilled = true;
      }
    });
    if (!isSourceFilled) return false;
  }

  for (const houseSource of group.HouseHoldEmissionSources) {
    let isGroupFilled = false;
    for (const childSource of houseSource.ChildSources) {
      if (childSource.Question && childSource.MetaValue) isGroupFilled = true;
      if (!childSource.Question && childSource.IsSelected) isGroupFilled = true;
    }
    if (group.HouseHoldEmissionSources.length) {
      if (!isGroupFilled) return false;
    }
  }

  return true;
};

const CO2InitialSurveyComponent = (props: CO2InitialSurveyProps): JSX.Element => {
  const context = useContext(CO2Context);
  const [{ co2Plan }] = useCO2();
  const [currentGroup, setCurrentGroup] = useState<CO2EmissionSourceGroup | null>(null);
  const [isLastGroup, setIsLastGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFirstGroup, setIsFirstGroup] = useState(false);
  const [isCurrentGroupFilled, setIsCurrentGroupFilled] = useState(false);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("lg"));
  const [flatGroups, setFlatGroups] = useState<CO2EmissionSourceGroup[]>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const { t } = useTranslation("translation");
  const isMissingInSurvey =
    !currentGroup?.SelectedSourceId &&
    context.missingSourceGroups.filter(([groupId, personId]) => groupId === currentGroup?.Id).length > 0;
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let groups = props.survey.Categories.flatMap((cat) => cat.EmissionSourceGroups);
    if (co2Plan?.CustomQuestions) {
      groups = integrateCustomQuestionsIntoGroups(
        co2Plan.CustomQuestions,
        groups,
        CO2PlanCustomQuestionsTarget.InitialSurvey
      );
    }

    setFlatGroups(groups);
  }, [props.survey, co2Plan?.CustomQuestions]);

  useEffect(() => {
    setIsLastGroup(false);
    const groups = [...flatGroups];
    const activeGroup = groups[props.survey.ActiveGroupIndex ?? 0];
    const isCustomQuestions = co2Plan?.CustomQuestions?.some(
      //CO2PlanCustomQuestionsTarget.InitialSurvey value is 0, but it could be simplified by server serialization
      (question) => question.Target === CO2PlanCustomQuestionsTarget.InitialSurvey || question.Target === undefined
    );
    setCurrentGroup(activeGroup);

    if (activeGroup?.Id !== currentGroup?.Id && !isCustomQuestions) {
      setIsFirstGroup(props.survey.ActiveGroupIndex === 0 || props.survey.ActiveGroupIndex === undefined);
      setIsLastGroup(props.survey.ActiveGroupIndex === groups.length - 1);

      props.scrollToTop();
    }

    if (/*props.survey.ActiveGroupIndex === undefined ||*/ flatGroups.length <= 0) return;

    if (props.survey.ActiveGroupIndex !== flatGroups.length - 1) {
      setIsCurrentGroupFilled(IsGroupFilled(activeGroup, props.planPersons, context.isMultiSelect));
    } else {
      setIsCurrentGroupFilled(IsGroupFilled(activeGroup, props.planPersons, context.isMultiSelect));
      setIsLastGroup(true);
    }
  }, [flatGroups, props.survey.ActiveGroupIndex]);

  useEffect(() => {
    if (currentGroup?.IsCustom) {
      setIsCurrentGroupFilled(getIsQuestionsFilled(currentGroup));
    }
  }, [currentGroup]);

 

  useEffect(() => {
    if (co2Plan?.InitialSurvey?.MissingSourceGroups?.length) {
      const groups = [...flatGroups];

      groups.find((group, index) => {
        if (!IsGroupFilled(group, props.planPersons, context.isMultiSelect)) {
          context.setActiveGroupIndex(index);
          setIsAlertOpen(true);
          return true;
        }
      });
    }
  }, [co2Plan?.InitialSurvey.MissingSourceGroups]);

  const saveEmisson = async (forceFinishSurve: boolean) => {
    setLoading(true);
    await context.saveEmissionSurvey(
      co2Plan?.InitialSurvey || null,
      null,
      co2Plan?.CustomQuestions || null,
      co2Plan?.Id,
      context.unit?.Id,
      context.isMultiSelect,
      props.planPersons
    );
    if (isLastGroup || forceFinishSurve) {
      await props.finishSurvey();
    }
    setLoading(false);
  };

  const forthButtonHandler = useMemo(() => {
    const setStep = () => {
      context.setActiveGroupIndex((props.survey.ActiveGroupIndex ?? 0) + 1);
    };

    const throwAlert = () => {
      if (!isAlertOpen) {
        setIsAlertOpen(true);
      }
    };

    if (!isCurrentGroupFilled) {
      return throwAlert;
    }
    setIsAlertOpen(false);

    if (isLastGroup) {
      return saveEmisson;
    }

    if (!props.survey.ActiveGroupIndex && context.isMultiSelect) {
      return async () => {
        await saveEmisson(false);
        setStep();
      };
    }

    return setStep;
  }, [
    context.isMultiSelect,
    props.survey.ActiveGroupIndex,
    isLastGroup,
    co2Plan?.CustomQuestions,
    isCurrentGroupFilled,
  ]);

  const handleInitialSurveyUpdate = async () => {
    await saveEmisson(true);
  };

  return (
    <Grid container direction="column" sx={{ flexWrap: "nowrap" }}>
      <Snackbar
        open={isAlertOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={(e, reason) => {
          if (reason !== "clickaway") {
            setIsAlertOpen(false);
          }
        }}
        autoHideDuration={5000}
      >
        <Alert variant="filled" severity="warning">
          {t("ClimatePlan.Errors.ForgotToAnswer")}
        </Alert>
      </Snackbar>
      {isMd && !props.ignorePlanPersons && (
        <StyledButton variant="outlined" onClick={() => props.openFamilyDialog()} size="small">
          <AiOutlineUsergroupAdd /> {t("ClimatePlan.General.Family")}
        </StyledButton>
      )}
      {props.survey.IsFilledIn && (
        <CO2InitialSurveyUpdateComponent
          groups={flatGroups}
          isInitialSurveyOutdated={co2Plan?.IsInitialSurveyOutdated ?? false}
          onSave={handleInitialSurveyUpdate}
          isAlertOpen={isAlertOpen}
          setIsAlertOpen={setIsAlertOpen}
          loading={loading}
        />
      )}
      {!props.survey.IsFilledIn && (
        <>
          <Grid item container justifyContent="center" sx={{ position: isMd ? "sticky" : "static", top: 0, zIndex: 2 }}>
            <Paper sx={{ padding: isXs ? "20px 8px" : "40px 80px", width: "100%" }}>
              {flatGroups?.length && (
                <CO2StepperComponent
                  activeStep={props.survey.ActiveGroupIndex ?? 0}
                  steppedGroups={flatGroups}
                  onActiveStepChanged={(index) => {
                    if (props.isReadonly) {
                      context.setActiveGroupIndex(index);
                    } else {
                      context.setActiveGroupIndex(index);
                    }
                  }}
                ></CO2StepperComponent>
              )}
            </Paper>
          </Grid>
          {currentGroup != null && !currentGroup.IsCustom && (
            <Grid item container marginBottom={2} marginTop={-2}>
              <Paper sx={{ padding: isXs ? "20px 8px" : "10px 80px 40px", width: "100%" }}>
                <Grid item>
                  <Typography align="center" variant="h4" style={isMissingInSurvey ? { color: "red" } : {}}>
                    {t(currentGroup.Name)}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          )}
          {currentGroup != null && !currentGroup.IsCustom && (
            <Grid item>
              <CO2EmissionSourceGroupComponent
                key={`emissionSourceGroup_${currentGroup.Id}`}
                group={currentGroup}
                absoluteIndex={props.survey.ActiveGroupIndex ?? 0}
                hideNextButton={!isCurrentGroupFilled}
                planPersons={props.planPersons}
              ></CO2EmissionSourceGroupComponent>
            </Grid>
          )}

          {currentGroup?.IsCustom && <CustomQuestionsComponent group={currentGroup} />}
          <ButtonsComponent
            back={!isFirstGroup ? () => context.setActiveGroupIndex((props.survey.ActiveGroupIndex ?? 0) - 1) : null}
            loading={loading}
            forth={() => forthButtonHandler(false)}
          />
        </>
      )}
    </Grid>
  );
};

const StyledButton = styled(Button)(() => ({
  maxWidth: "150px",
  marginLeft: "auto",
  marginBottom: 8,
  "@media print": {
    display: "none",
  },
}));
export default CO2InitialSurveyComponent;
