import { Reducer } from "redux";
import { AppAction } from "../definitions/Action";
import { Tender } from "../definitions/model/ServiceBook";
import { TenderActions } from "../constants/tender.actiontypes";
import { Contractor, WorkerModel } from "../definitions/model/Worker";

export interface TenderStoreState {
  loading: boolean;
  updating: boolean;
  error: string | null;
  errorSeverity: "success" | "info" | "warning" | "error" | null;
  tenders: Tender[];
  archivedTenders: Tender[];
  tenderData: {
    contractors: WorkerModel[];
    tender: Tender | null;
  };
  contractor: Contractor | null;
}

export const initialState: TenderStoreState = {
  //common state data
  loading: false,
  updating: false,
  error: null,
  errorSeverity: null,
  tenders: [],
  archivedTenders: [],
  tenderData: {
    contractors: [],
    tender: null,
  },
  contractor: null,
};

const tenderReducer: Reducer<TenderStoreState, AppAction> = (
  state: TenderStoreState = initialState,
  action: AppAction
): TenderStoreState => {
  switch (action.type) {
    case TenderActions.GET_TENDERS:
    case TenderActions.GET_ARCHIVED_TENDERS:
    case TenderActions.GET_CONTRACTOR_TENDERS:
    case TenderActions.GET_CONTRACTOR_ARCHIVED_TENDERS:
    case TenderActions.GET_TENDER:
    case TenderActions.GET_CONTRACTOR_DETAILS:
      return { ...state, loading: true };
    case TenderActions.CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errorSeverity: null,
      };
    case TenderActions.GET_TENDERS_SUCCEEDED:
    case TenderActions.GET_CONTRACTOR_TENDERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenders: action.payload.Results,
        archivedTenders: [],
        tenderData: {
          contractors: [],
          tender: null,
        },
      };
    case TenderActions.GET_ARCHIVED_TENDERS_SUCCEEDED:
    case TenderActions.GET_CONTRACTOR_ARCHIVED_TENDERS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        archivedTenders: action.payload.Results,
      };
    case TenderActions.UPDATE_TENDER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenderData: {
          ...state.tenderData,
          tender: action.payload,
        },
      };
    case TenderActions.ARCHIVE_TENDER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenders: state.tenders.filter((tender) => tender.Id !== action.payload.Id),
      };
    case TenderActions.GET_TENDER_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenderData: {
          ...state.tenderData,
          tender: action.payload,
        },
      };
    case TenderActions.GET_TENDER_CONTRACTORS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tenderData: {
          ...state.tenderData,
          contractors: action.payload,
        },
      };
    case TenderActions.GET_CONTRACTOR_DETAILS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        contractor: action.payload,
      };
    default:
      return state;
  }
};

export default tenderReducer;
