import React, { ChangeEventHandler, FocusEventHandler, forwardRef  } from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
import { InputProps, TextField, TextFieldProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { convertToCurency } from "../../services/converter.service";
import { useController } from "react-hook-form";
import { mergeRefs } from "react-merge-refs";

//this section is to integrate NumberFormat with MaterialUI
//handleChange is possibly redundant
export const CurrencyField = (props: TextFieldProps) => {
  const useNumericFormat = false; // Set to true for NumericFormat, false for TextField
  const handleChangeTextField = (e: any) => {
    if (e.target.value) {
      const separator = /\./gi;
      const val = e.target.value.replace(separator, "");
      e.target.value = convertToCurency(val, ".");
    }

    props.onChange?.(e);
  };

  const handleChangeNumericFormat = (e: any) => {
    props?.onChange?.(e);
  };
  const value = props.value ? convertToCurency(props.value as number, ".") : props.value;
  const defaultValue = props.defaultValue ? convertToCurency(props.defaultValue as number, ".") : props.defaultValue;

  return (<>
    {useNumericFormat && <NumericFormat
      {...props}
      value={props.value as number | null | undefined}
      defaultValue={props.defaultValue as number | null | undefined}
      type="text"
      thousandSeparator="."
      decimalSeparator=","
      valueIsNumericString
      decimalScale={2}
      allowNegative={false}
      customInput={TextField}
      onChange={handleChangeNumericFormat}
    />}
    {!useNumericFormat &&
      <TextField
        {...props}
        value={value}
        defaultValue={defaultValue}

        onChange={handleChangeTextField}
        inputProps={{
          style: { textAlign: "right" },
        }}
      />}</>
  );

};

export type Formatter<T = string> = {
  format: (value: T) => string;
  parse: (value: string) => T;
};

export const defaultFormatter: Formatter = {
  format: (value) => value,
  parse: (value) => value,
};

export const currencyFormatter: (config?: {
  locale?: string;
  currency?: string;
}) => Formatter<number> = ({ locale = "da-DK", currency = "DKK" } = {}) => {
  const numberFormatter = new Intl.NumberFormat(locale, {
    style: "decimal",
    // minimumFractionDigits: 0,
    // maximumFractionDigits: 2,
  });

  return {
    format: (value: number) => {
      return numberFormatter.format(value);
    },
    parse: (value: string) => {
      // const rawValue = parseInt(value.replace(/\D/g, ""), 10) || 0;
      const rawValue = parseFloat(value.replace(/\./g, "")) || 0;

      return rawValue;// / 100;
    },
  };
};

type ControlledInputProps = {
  name: string;
  formatter?: Formatter<any>;
} & TextFieldProps;

export const ControlledFormattedField = forwardRef<HTMLInputElement, ControlledInputProps>(
  ({ name, formatter = defaultFormatter, ...props }, ref) => {
    const { field } = useController({
      name,
      defaultValue: props.value,
    });

    const inputRef = mergeRefs([ref, field.ref]);

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      field.onChange(formatter.parse(event.target.value));
      props.onChange?.(event);
    };

    const onBlur: FocusEventHandler<HTMLInputElement> = (event) => {
      field.onBlur();
      props.onBlur?.(event);
    };

    const inputValue = field.value ? formatter.format(field.value ?? "") : "";

    return (
      <TextField
        {...props}
        name={name}
        ref={inputRef}
        onChange={onChange}
        onBlur={onBlur}
        value={inputValue}
      />
    );
  },
);

ControlledFormattedField.displayName = "ControlledFormattedField";

export const PhoneField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };

  return (
    <MuiTelInput
      {...props}
      value={props.value as string}
      onChange={handleChange}
      variant={props.variant}
      inputRef={props.inputRef}
    />
  );
};

export const PostCodeField = (props: TextFieldProps) => {
  const handleChange = (e: any) => {
    props?.onChange?.(e);
  };

  return (
    <PatternFormat
      format="####"
      {...props}
      value={props.value as string | number | null | undefined}
      defaultValue={props.defaultValue as string | number | null | undefined}
      type="text"
      onChange={handleChange}
      customInput={TextField}
    />
  );
};
