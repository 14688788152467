import { CO2EconomyItem, CO2EmissionSource, CO2EmissionSourceGroup, CO2EmissionSurvey, CO2PlanPerson } from "./CO2Plan";
import CarIcon from "./components/img/klima-icons/Transport-Icon.svg";
import ElectricityIcon from "./components/img/klima-icons/Appliances-Icon.svg";
import FoodIcon from "./components/img/klima-icons/Food-Icon.svg";
import HomeIcon from "./components/img/klima-icons/Home-Icon.svg";
import MobileIcon from "./components/img/klima-icons/Mobile-Icon.svg";
import ShoppingIcon from "./components/img/klima-icons/Clothing-Icon.svg";
import VacationIcon from "./components/img/klima-icons/Travel-Icon.svg";
import QuestionIcon from "./components/img/klima-icons/Question-Icon.svg";
import { GroupToShow } from "./components/CO2EmissionChartComponent";
import { ClimatPlanUser } from "./ClimatePlanPage";

export const IsGroupFilled = (group: CO2EmissionSourceGroup, planPersons: CO2PlanPerson[], isMultiSelect?: boolean) => {
  if(!group) return false;
  
  if (group.IsPerPerson) {
    return (
      group.PersonEmissionSources.filter((person) => {
        const currentPerson = planPersons.find((listPerson) => {
          return listPerson.Id === person.PersonId;
        });

        if (currentPerson?.BirthYear) {
          const currentYear = new Date().getFullYear();
          if (currentYear - currentPerson.BirthYear <= 6) return true;
        }
        const selectedChild = person.EmissionSources.find((src) => {
          return src.Id === person.SelectedSourceId;
        });
        if (selectedChild?.CombinedMaxAmount != null) {
          const selectedSources = person.EmissionSources.filter((s) => s.IsSelected);
          if (selectedSources == null || selectedSources.length == 0) return false;

          const combinedAmount = selectedSources.reduce((a, b) => a + (b.CombinedValue ?? 0), 0);
          if (isMultiSelect && !combinedAmount) return false;
          if (combinedAmount > selectedChild.CombinedMaxAmount) return false;

          if (isMultiSelect) {
            //check selection for the multiselect
            const errorChilds = selectedSources.filter((s) => s.ChildSources?.filter((c) => c.IsSelected).length == 0);
            if (errorChilds?.length > 0) return false;
          }

          return true;
        }
        return !!selectedChild && isSourceFilled(selectedChild);
      }).length === group.PersonEmissionSources.length || !!isMultiSelect
    );
  } else {
    const selectedChild = group.HouseHoldEmissionSources.find((src) => src.Id === group.SelectedSourceId);
    return !!selectedChild && isSourceFilled(selectedChild);
  }
};

const isSourceFilled = (source: CO2EmissionSource): boolean => {
  if (!source.IsSelected) {
    return false;
  }
  if (source.ChildSources?.length > 0) {
    const selectedChild = source.ChildSources.find((src) => {
      return src.Id === source.SelectedChildSourceId;
    });
    return !!selectedChild && isSourceFilled(selectedChild);
  }

  if (source.HasOptions) {
    return !!source.SelectedOptionId;
  }

  return true;
};

export const GetSourceCost = (source: CO2EmissionSource, CustomEconomyCost: number | null): number => {
  let value =
    CustomEconomyCost ??
    (source.Value !== null && source.Value > 0 ? source.Value : source.ValueInitial ?? 0) *
      (source.PricePerUnit ?? 0) *
      365;

  if (source.CombinedMaxAmount != null) {
    value = (value * (source.CombinedValue ?? 0)) / source.CombinedMaxAmount;
  }
  return value;
};

const GetHouseholdEmissionSource = (sources: CO2EmissionSource[], selectedId: string): CO2EmissionSource => {
  let source = sources.filter((s) => s.Id == selectedId)[0];

  if (source.SelectedChildSourceId)
    source = GetHouseholdEmissionSource(source.ChildSources, source.SelectedChildSourceId);

  return source;
};

const GetEconomyItems = (economyItems: Array<CO2EconomyItem>, sourceId: string) => {
  return economyItems.map((e) => {
    const economyItem = { ...e };
    const subCategoryLink = economyItem.Links.find((l) =>
      l.Categories.find((category) => category.CategoryId === sourceId)
    );
    if (subCategoryLink) economyItem.ExternalLink = subCategoryLink.Link;

    return economyItem;
  });
};

export const GetEconomyData = (
  activeGroup: CO2EmissionSourceGroup,
  initialGroup: CO2EmissionSourceGroup,
  personId?: string
) => {
  let personsActiveSourceCost = 0;
  let personsInitialSourceCost = 0;
  let initialEmission = 0;
  let activeEmission = 0;
  let activeSourseName = "";
  let initialSourseName = "";
  let activeSourceTargetName = "";
  let initialSourceTargetName = "";
  let economyItems: Array<CO2EconomyItem> = [];

  let initialPriceSources: CO2EmissionSource[] = [];

  const personInPlan = !!activeGroup.PersonEmissionSources.find((s) => s.PersonId == personId);
  for (const personEmission of activeGroup.PersonEmissionSources) {
    if (personInPlan && personEmission.PersonId != personId) continue;

    const initialPersonEmission = initialGroup.PersonEmissionSources.find(
      (p) => p.PersonId === personEmission.PersonId
    );

    if (initialPersonEmission) {
      initialPriceSources = initialPersonEmission.EmissionSources.filter((s) => s.IsSelected);
    }

    const activeSources = personEmission.EmissionSources.filter(
      (s) => s.IsSelected // Id === personEmission.SelectedSourceId
    );

    let biggestEconomy = 0;
    for (let i = 0; i < activeSources.length; i++) {
      const activeSource = activeSources[i];

      if (initialPriceSources[i] && initialPersonEmission) {
        const initialEconomy = GetSourceCost(initialPriceSources[i], initialPersonEmission.CustomEconomyCost);
        const activeEconomy = GetSourceCost(activeSource, personEmission.CustomEconomyCost);
        if (initialEconomy - activeEconomy > biggestEconomy) {
          biggestEconomy = initialEconomy - activeEconomy;
          economyItems = GetEconomyItems(activeGroup.EconomyItems, activeSource.Id);
          initialSourceTargetName = initialPriceSources[i].TargetName || "";
          initialSourseName = initialPriceSources[i].Name;
          activeSourceTargetName = activeSource?.TargetName || "";
          activeSourseName = activeSource.Name;
        }
      }

      if (activeSource?.Value != null && activeSource?.PricePerUnit != null) {
        activeEmission += activeSource.CO2Emission;

        personsActiveSourceCost += GetSourceCost(activeSource, personEmission.CustomEconomyCost);
      }
    }

    if (initialPersonEmission) {
      const initialPriceSources = initialPersonEmission.EmissionSources.filter((s) => s.IsSelected); // GetPersonEmissionSource(initialPersonEmission);
      for (const initialPriceSource of initialPriceSources) {
        if (!initialSourceTargetName) initialSourceTargetName = initialPriceSource.TargetName || "";
        if (!initialSourseName) initialSourseName = initialPriceSource.Name;
        if (initialPriceSource?.PricePerUnit != null) {
          initialEmission += initialPersonEmission.CO2Emission;
          personsInitialSourceCost += GetSourceCost(initialPriceSource, initialPersonEmission.CustomEconomyCost);
        }
      }
    }
  }

  if (activeGroup.HouseHoldEmissionSources && activeGroup.SelectedSourceId && initialGroup.SelectedSourceId) {
    const activeSource = GetHouseholdEmissionSource(activeGroup.HouseHoldEmissionSources, activeGroup.SelectedSourceId);
    const initialSource = GetHouseholdEmissionSource(
      initialGroup.HouseHoldEmissionSources,
      initialGroup.SelectedSourceId
    );
    economyItems = GetEconomyItems(activeGroup.EconomyItems, activeSource.Id);

    activeSourseName = activeSource.Name;
    activeSourceTargetName = activeSource.Name;
    initialSourseName = initialSource.Name;
    initialSourceTargetName = initialSource.Name;
    activeEmission += activeSource.CO2Emission;
    initialEmission += initialSource.CO2Emission;

    personsActiveSourceCost += GetSourceCost(activeSource, activeGroup.CustomEconomyCost);
    personsInitialSourceCost += GetSourceCost(initialSource, initialGroup.CustomEconomyCost);
  }

  return {
    personsActiveSourceCost: Math.round(personsActiveSourceCost),
    personsInitialSourceCost: Math.round(personsInitialSourceCost),
    activeEmission: Math.round(activeEmission),
    initialEmission: Math.round(initialEmission),
    economyItems,
    initialSourceTargetName,
    activeSourceTargetName,
    initialSourseName,
    activeSourseName,
  };
};

export const convertType = function (value: any) {
  try {
    return new Function("return " + value + ";")();
  } catch (e) {
    return value;
  }
};

export function getSurveyPersonsEmission(survey: CO2EmissionSurvey, unconditionalEmission: number): GroupToShow[] {
  const groups = survey.Categories.flatMap((cat) => cat.EmissionSourceGroups);
  const personGroups = groups.filter((gr) => gr.IsPerPerson);
  const householdGroups = groups.filter((gr) => !gr.IsPerPerson);
  const colors = ["#eb8ff9", "#a0ed6e", "#ffba27", "#99ecff", "#fff048", "#69bfff"];
  if (personGroups.length) {
    const newPersons = personGroups[0].PersonEmissionSources.map((person, index) => ({
      ShortName: person.Name,
      Tooltip: "",
      Id: person.PersonId,
      Emission: 0,
      color: colors[index % colors.length],
    }));
    const personEmissionSourceGroups = personGroups.flatMap((group) => group.PersonEmissionSources);
    personEmissionSourceGroups.forEach((record) => {
      const person = newPersons.find((p) => p.Id === record.PersonId);
      if (person) {
        person.Emission += record.CO2Emission;
      }
    });

    const totalHouseholdEmission = householdGroups.reduce((result, gr) => result + gr.CO2Emission, 0);
    const houseHoldEmissionPerPerson = Math.round((totalHouseholdEmission + unconditionalEmission) / newPersons.length);
    newPersons.forEach((person) => (person.Emission += houseHoldEmissionPerPerson));
    return newPersons;
  }
  return [];
}

export function getCurrenUserEmission(persons: GroupToShow[], user: ClimatPlanUser | null): number {
  // setPersons(newPersons);
  let currentPerson = persons[0];
  if (user !== null) {
    const foundByName = persons.find((x) => x.ShortName === user?.Name);
    if (foundByName) {
      currentPerson = foundByName;
    }
    const foundById = persons.find((x) => x.Id === user?.Id);
    if (foundById) {
      currentPerson = foundById;
    }
  }
  return currentPerson.Emission;
  // setUsersEmission(currentPerson.Emission);
}

export const getBarometerValue = (
  defaultCO2Emission: number,
  governmentGoalCO2Emission: number,
  emission: number,
  planPersonsNumber: number
) => {
  // function should return a value that fits following conditions:
  // min - 0
  // governmentGoalEmission - 25
  // defaultEmission - 88
  // max - 100

  const isWithinLimit = governmentGoalCO2Emission / planPersonsNumber > emission;
  // greenZone - is max value of green zone on a barometer
  const greenZone = 21;

  // assume barometer value is a lineral function: rotation = a * emission + b
  const a = (88 - 25) / (defaultCO2Emission / planPersonsNumber - governmentGoalCO2Emission / planPersonsNumber);
  const b = 88 - (a * defaultCO2Emission) / planPersonsNumber;
  let result = a * emission + b;
  result = Math.max(0, result);
  result = Math.min(100, result);
  if (isWithinLimit && result > greenZone * 0.85) result = result * 0.85;
  return result;
};

export const getHouseBarometerValue = (
  defaultCO2Emission: number,
  governmentGoalCO2Emission: number,
  emission: number
) => {
  const isWithinLimit = governmentGoalCO2Emission > emission;
  // greenZone - is max value of green zone on a barometer
  const greenZone = 21;

  const a = (88 - 25) / (defaultCO2Emission - governmentGoalCO2Emission);
  const b = 88 - a * defaultCO2Emission;
  let result = a * emission + b;
  result = Math.max(0, result);
  result = Math.min(100, result);

  //this is needed to set the barometer arrow to the green zone if emissions are below the government Goal CO2Emission
  if (isWithinLimit && result > greenZone * 0.85) result = result * 0.85;
  return result;
};

export const GetIcon = (icon: string) => {
  switch (icon) {
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Phone":
      return MobileIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Food":
      return FoodIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Electronics":
      return ElectricityIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Holiday":
      return VacationIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Clothes":
      return ShoppingIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Residential":
      return HomeIcon;
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Transport":
      return CarIcon;
    case "Total":
      return "";
    default:
      return QuestionIcon;
  }
};
export const GetColor = (color: string) => {
  switch (color) {
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Phone":
      return "#ffba27";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Food":
      return "#ff8380";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Electronics":
      return "#fff048";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Holiday":
      return "#a0ed6e";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Clothes":
      return "#99ecff";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Residential":
      return "#eb8ff9";
    case "ClimatePlan.CO2CalculatorDB.EmissionSourceGroup.Transport":
      return "#69bfff";
    default:
      return "#569790";
  }
};
